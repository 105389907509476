<template>
  <div>
    <div class="p-grid">
      <div class="p-col">
        <h3><i class="pi pi-globe" style="font-size: 1.6rem"></i> Seguradoras</h3>
      </div>
      <div class="p-col p-text-right">
        <Button label="nova seguradora" icon="pi pi-plus" class="p-button p-button-secondary"  @click="$router.push({ path: `/seguradora` })" v-if="$auth.userCan('SEGURADORAS_CADASTRAR')" />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col">
        <AutoComplete v-model="filtrosSelecionados"  :completeOnFocus="true" :multiple="true" :suggestions="filtrosResultados" @complete="filtrarAutoComplete($event)" 
        field="completeLabel" optionGroupLabel="label" optionGroupChildren="items" placeholder="Filtre seus resultados" style="display: block">
          <template #item="slotProps">
            <div>{{slotProps.item.label}}</div>
          </template>
        </AutoComplete>
      </div>
    </div>

    <DataTable sortMode="single" sortField="nome" :sortOrder="1" :value="seguradoras" :loading="loading" selectionMode="single" v-model:selection="seguradora">
      <Column :sortable="true" field="nome" header="Nome"></Column>
      <Column :sortable="true" field="prioridade" header="Prioridade" class="col-prioridade"></Column>
      <Column :sortable="true" field="status" header="Status" class="col-status"></Column>
      <Column class="col-actions">
        <template #body="slotProps">
            <Button icon="pi pi-trash" v-tooltip.left="'Excluir Seguradora'" class="p-button-rounded p-button-danger" @click="excluir($event, slotProps.data)" v-if="$auth.userCan('SEGURADORAS_EXCLUIR')" />
        </template>
      </Column>
      <template #empty>
          Nenhuma Seguradora encontrada.
      </template>
    </DataTable>

  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

export default {
  
  data() {
    return {

      // AUTOCOMPLETE OPCIONAL: //
      filtrosPersonalizados: 'Nome',
      // AUTOCOMPLETE OBRIGATORIOS: //
      filtrosSelecionados: null,
      filtrosResultados: [],
      filtrosOpcoes: [],
      // FIM AUTOCOMPLETE //

      loading: true,
      api_get: '?fields=nome,status,prioridade',
      seguradoras: [],
      seguradora: null,

    };
  },

  mounted() {

    const self = this;

    this.$api.get('/seguradoras/filtros').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.filtrosOpcoes = dados.data;
 
      }
      
    });

    this.$api.get('/seguradoras' + this.api_get).then(function (response) {

      const dados = response.data;

      if(dados.success) {
        self.seguradoras = dados.data;
      }

      self.loading = false;
      
    });

  },

  watch: {

    filtrosSelecionados(v) {

      const self = this;
      var filtrosQuery = "";

      self.loading = true;

      if(Array.isArray(v) && v.length > 0) {
        var p = {};
        this.filtrosSelecionados.forEach((f) => {
          if(typeof p[f.group] == "undefined")
            p[f.group] = [];
          p[f.group].push(f.id);
        });
        filtrosQuery = JSON.stringify(p); //TODO PASSAR PARA A URL PARA QUE QUANDO A PESSOA ATUALIZAR A TELA OS FILTROS PERMANEÇAM
      }

      this.$api.get('/seguradoras'  + this.api_get + '&filters=' + filtrosQuery).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.seguradoras = dados.data;
        }

        self.loading = false;
        
      });


    },

    seguradora: function(val) {
      if(val != null) {
        this.$router.push({ path: `/seguradora/${val.id}` });
      }
    },


  },

  methods: {

    filtrarAutoComplete: function($e) {

      const self = this;

      const q = $e.query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      var ids_selecionados = [];
      if(self.filtrosSelecionados != null) {
        self.filtrosSelecionados.forEach((i) => {
          ids_selecionados.push(i.id);
        });
      }

      self.filtrosResultados = [];
      for (const _g of Object.values(self.filtrosOpcoes)) {
        const g = Object.assign({}, _g);
        var el = [];
        for (const _e of Object.values(g.items)) {
          const e = Object.assign({}, _e);
          if(e.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(q) >= 0) {
            if(ids_selecionados.indexOf(e.id) == -1) {
              e.completeLabel = g.label + ': ' + e.label; // Mudança na apresentação do dado selecionado
              el.push(e);
            }
          }
        }
        if(el.length > 0) {
          g.items = el;
          self.filtrosResultados.push(g);
        }
      }

      if(typeof self.filtrosPersonalizados == 'string') {

        self.filtrosResultados.push({
          label: self.filtrosPersonalizados, id: 'str', 
          items: [
            {label: $e.query, id: $e.query, group: 'str', completeLabel: self.filtrosPersonalizados + ': ' + $e.query}
          ]
        });

      }

    },

    excluir(event, obj) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          this.$api.post('/seguradoras/' + obj.id, {
            '_method' : 'DELETE'
          }).then(function (response) {

            if(response.data.success) {

              for(const k of Object.keys(self.seguradoras)) {
                if(self.seguradoras[k].id == obj.id) {
                  self.seguradoras.splice(k,1);
                  break;
                }
              }

              self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            } else {
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
            }

          }).catch(function (erro) {

            if(typeof erro.response != "undefined")
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

          });

        }
      });
      
    },

  }
};

</script>

<style scoped>

  :deep(.col-status), :deep(.col-prioridade) {
    width: 120px;
  }

</style>